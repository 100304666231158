import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import heroimg from "./../../assets/png/trading-hero.png";
import playStore from "./../../assets/svg/play-store.svg";
import appStore from "./../../assets/svg/app-store.svg";

function Hero() {
  const { t } = useTranslation();

  return (
      <div className="hero-bg4">
        <div className=" py-14 2xl:py-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 pt-20 xl:pt-40 font-poppins">
            <div className="flex md:flex-row flex-col justify-between gap-6">
              <div className="max-w-[748px] lg:mt-20">
                <h1 className="text-white font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
                  {t("tradingHero.title")}
                </h1>
                <p className="text-white text-base sm:text-lg font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t("tradingHero.description")}
                </p>
                <h3 className="my-4 md:my-5 lg:mt-8 text-white text-lg md:text-xl font-graphik_semibold">
                  {t("tradingHero.download_now")}
                </h3>
                <div className="flex items-center gap-3 sm:gap-4">
                  <Link to="https://release.sanketsfx.com/MTc=" target ="_blank"   className="max-w-[120px] sm:max-w-[168px]">
                    <img src={playStore} alt="play store" className="w-full" />
                  </Link>
                  <Link to="/" className="max-w-[120px] sm:max-w-[168px]">
                    <img src={appStore} alt="app store" className="w-full" />
                  </Link>
                </div>
                <h3 className="my-4 md:my-5 lg:mt-8 text-white text-lg sm:text-xl lg:text-2xl font-graphik_semibold">
                  {t("tradingHero.conditions")}
                </h3>
                <p className="text-white text-base font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t("tradingHero.conditions_description")}
                </p>
                <ul className="flex flex-col mt-4 md:mt-6 gap-2 sm:gap-3 2xl:gap-6 list-disc ps-4 md:ps-5 white-dot">
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("tradingHero.overview")}:
                  </span>{" "}
                    {t("tradingHero.overview_description")}
                  </li>
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("tradingHero.trading_instruments")}:
                  </span>{" "}
                    {t("tradingHero.trading_instruments_description")}
                  </li>
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("tradingHero.fees_and_charges")}:
                  </span>{" "}
                    {t("tradingHero.fees_and_charges_description")}
                  </li>
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("tradingHero.account_types")}:
                  </span>{" "}
                    {t("tradingHero.account_types_description")}
                  </li>
                </ul>
              </div>
              {/* <div className="xl:max-w-[523px] lg:min-w-[500px] max-w-[280px] md:max-w-[350px] mx-auto md:mx-0 relative z-20">
                <img src={heroimg} alt="phone img" className="w-full" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Hero;
